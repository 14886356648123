import React, { useState, useEffect } from "react";
import "./UserList.css";
import UserCard from "../UserCard/UserCard.jsx";
import mainApi from "../../utils/api.js";

const UserList = ({ users, handleSubscription, handleDeleteUser, handleSignOut }) => {
  const handleExpiredSubscription = (users) => {
    // users.forEach((user) => {
    //   const status = user.subscription.status;
    //   const endDates = user.subscription.end;

    //   if (endDates && endDates.length > 0) {
    //     const lastEndDate = new Date(endDates[endDates.length - 1]);
    //     const now = new Date();

    //     if (now > lastEndDate && status) {
    //       const dataToUpdate = {
    //         subscription: {
    //           status: false,
    //           ae: false,
    //           pp: false,
    //           mg: false,
    //           am: false,
    //           dr: false,
    //         },
    //       };

    //       mainApi
    //         .updateSubscription(user._id, dataToUpdate)
    //         .then((response) => {
    //           console.log("Subscription updated for user:", user._id);
    //         })
    //         .catch((error) => {
    //           console.error(
    //             "Failed to update subscription for user:",
    //             user._id,
    //             error
    //           );
    //         });
    //     }
    //   }
    // });
  };

  function findDuplicateHostNames(data) {
    const hostMap = {};  // Объект для хранения hostName и соответствующих пользователей

    // Перебираем каждый объект в массиве
    data.forEach(item => {
        if (item.license && item.license.hostName) {
            const hostName = item.license.hostName;
            // Если hostName уже есть в map, добавляем пользователя к списку
            if (hostMap[hostName]) {
                hostMap[hostName].push(item);
            } else {
                // Если нет, создаем новый массив с этим первым пользователем
                hostMap[hostName] = [item];
            }
        }
    });

    // Фильтруем и возвращаем только те hostNames, у которых более одного пользователя
    const duplicates = {};
    Object.keys(hostMap).forEach(hostName => {
        if (hostMap[hostName].length > 1) {
            duplicates[hostName] = hostMap[hostName];
        }
    });

    return duplicates;
}
  useEffect(() => {
    console.log(users)
    handleExpiredSubscription(users);
    console.log(findDuplicateHostNames(users))
  }, [users]);

  const [filter, setFilter] = useState(
    localStorage.getItem("userFilter") || "all"
  ); // 'all', 'subscribed', 'notSubscribed'

  useEffect(() => {
    localStorage.setItem("userFilter", filter);
  }, [filter]);

  const filteredUsers = users
    .filter((user) => {
      if (filter === "subscribed") return user.subscription.status;
      if (filter === "notSubscribed") return !user.subscription.status;
      return true;
    })
    .sort((a, b) => {
      if (filter === "subscribed") {
        const aEndDates = a.subscription.end || [];
        const bEndDates = b.subscription.end || [];
        if (aEndDates.length > 0 && bEndDates.length > 0) {
          const aLastEndDate = new Date(aEndDates[aEndDates.length - 1]);
          const bLastEndDate = new Date(bEndDates[bEndDates.length - 1]);
          return aLastEndDate - bLastEndDate;
        }
      }
      return 0;
    });

  return (
    <section className="users-card">
      <div className="filter-buttons">
        <div
          className={`filter-buttons__button ${
            filter === "all" ? "filter-buttons__button_active" : ""
          }`}
          onClick={() => setFilter("all")}
        >
          Все пользователи
        </div>
        <div
          className={`filter-buttons__button ${
            filter === "subscribed" ? "filter-buttons__button_active" : ""
          }`}
          onClick={() => setFilter("subscribed")}
        >
          С подпиской
        </div>
        <div
          className={`filter-buttons__button ${
            filter === "notSubscribed" ? "filter-buttons__button_active" : ""
          }`}
          onClick={() => setFilter("notSubscribed")}
        >
          Без подписки
        </div>
      </div>
      {filteredUsers.length ? (
        <ul className="users-card__list">
          {filteredUsers.map((data) => (
            <UserCard
              key={data._id}
              user={data}
              handleSubscription={handleSubscription}
              handleDeleteUser={handleDeleteUser}
              handleSignOut={handleSignOut}
            />
          ))}
        </ul>
      ) : (
        <p>Пользователи не найдены</p>
      )}
    </section>
  );
};

export default UserList;
